import { GetLogDetalhado } from "@/models/LogsModel";
import ApiService from "./ApiService";

/**
 * @description lista os itens reprovados da oficina digital
 */

const getLogDetalhado = async (payload) : Promise<GetLogDetalhado> => {
    const { data } = await ApiService.post(`/logDetalhado/`,payload);
    return data;
};

const paginate = async (link) : Promise<GetLogDetalhado> => {
    const { data } = await ApiService.post(link,{});
    return data;
 };

export { getLogDetalhado,paginate }
