
import { useStore } from 'vuex';
import useEmitter from '@/composables/Emmiter'
import { computed, onMounted, ref } from 'vue';
import { LogUserDetalhado, GetLogDetalhado } from '@/models/LogsModel'
import { getLogDetalhado, paginate } from '@/services/LogDetalhado';
import { ContentLoader } from 'vue-content-loader';
import useAlert from "@/composables/Alert";

interface filter {
    codUsuario: string,
}


export default {
    name: 'log-detalhado',
    components: {
        ContentLoader
    },
    setup() {
        const store = useStore();
        const emitter = useEmitter();
        const codNivel = store.getters.getCodNivel;
        const usuarioLogado = computed(() => store.getters.usuarioLogado)
        const tableLog = ref<LogUserDetalhado[]>([])
        const paginateInfo = ref<GetLogDetalhado>()
        const currentPage = ref(1)
        const search = ref('')
        const delayTimer = ref(0);
        const animate = ref(false)
        const { showTimeAlert } = useAlert();

        onMounted(() => {
            if (codNivel == 2) {
                getDataTableLogDetalhado(usuarioLogado.value.codUsuario)
            } else {
                getDataTableLogDetalhado(undefined)
            }
        })

        emitter.on("filter-log-user-detalhamento", async (data: filter) => {
            getDataTableLogDetalhado(data.codUsuario,search.value)
        })

        const getDataTableLogDetalhado = async (codUsuario: string | undefined, search: string = ''): Promise<void> => {
            animate.value = true
            const response = await getLogDetalhado({ codUsuario, searchTerm: search == '' ? undefined : search })
            currentPage.value = 1
            tableLog.value = response.data
            paginateInfo.value = response
            animate.value = false
        }

        function filterData() {
            clearTimeout(delayTimer.value);
            delayTimer.value = setTimeout(function () {
                if (codNivel == 2) {
                    getDataTableLogDetalhado(usuarioLogado.value.codUsuario, search.value)
                } else {
                    getDataTableLogDetalhado(undefined, search.value)
                }
            }, 300);
        }

        const pagination = async (pageSelected: number = 1): Promise<void> => {
            currentPage.value = pageSelected
            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
            try {
                animate.value = true
                const response = await paginate(`logDetalhado?page=${pageSelected}`)
                tableLog.value = response.data
                paginateInfo.value = response
                animate.value = false
            } catch (error) {
                showTimeAlert("Não foi possível completar a solicitação", "error");
            }
        };

        const cutString = (string: string): string => {
            if (string.length > 30) {
                return string.substring(0, 30) + "...";
            } else {
                return string;
            }
        }

        return {
            tableLog,
            pagination,
            paginateInfo,
            currentPage,
            search,
            filterData,
            animate,
            cutString,
        }
    }
}
